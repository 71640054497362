import { createSelector } from 'reselect';

import { getPostMap, getPostByStableIdMap } from './post-selectors';

const getRecentPostsIds = (state, postId) => state.recentPostsIds[postId];

export const getRecentPosts = createSelector(
  getPostMap,
  getPostByStableIdMap,
  getRecentPostsIds,
  (posts, postsByStableId, recentPostsIds) => (recentPostsIds || []).map((id) => posts[id] || postsByStableId[id]),
);
